/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { MyUser } from './MyUser';

export type Project = {
  id: number;
  name: string;
  location: string;
  customer: string;
  visibleLayers: number;
  myUser: MyUser;
  collaborators: Array<MyUser>;
  finished: boolean;
  projectGroup: Project.projectGroup;
  isRoomAutomation: boolean;
  feedbackContact: Project.feedbackContact;
  state: Project.state;
  clipType: Project.clipType;
  discount: number;
  surcharge: number;
  exportBaumannByAsp: boolean;
  isFavorite: boolean;
  localOverrideOperation?: Project.localOverrideOperation;
  createdAt?: string;
  updatedAt?: string;
  favorite?: boolean;
  roomAutomation?: boolean;
};

export namespace Project {

  export enum projectGroup {
    UNDEFINED = 'UNDEFINED',
    OTHER = 'OTHER',
    TRANSPORT = 'TRANSPORT',
    LOGISTICS = 'LOGISTICS',
    SCHOOL = 'SCHOOL',
    RETAIL = 'RETAIL',
    EVENT = 'EVENT',
    OFFICE = 'OFFICE',
    HEALTH = 'HEALTH',
  }

  export enum feedbackContact {
    WITH = 'WITH',
    WITHOUT = 'WITHOUT',
  }

  export enum state {
    NOT_STARTED = 'NOT_STARTED',
    IN_PROGRESS = 'IN_PROGRESS',
    FINISHED = 'FINISHED',
    CANCELLED = 'CANCELLED',
    SENT_COST_ESTIMATION = 'SENT_COST_ESTIMATION',
    SENT_OFFER = 'SENT_OFFER',
    ASSIGNMENT_ACCEPTED = 'ASSIGNMENT_ACCEPTED',
    ASSIGNMENT_DENIED = 'ASSIGNMENT_DENIED',
  }

  export enum clipType {
    SCREW = 'SCREW',
    SPRING = 'SPRING',
  }

  export enum localOverrideOperation {
    NEVER = 'NEVER',
    NONE = 'NONE',
    TMU = 'TMU',
    BMD = 'BMD',
    COUPLING_RELAY = 'COUPLING_RELAY',
  }


}


/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { HierarchyItemRelationId } from './HierarchyItemRelationId';
import type { Item } from './Item';
import type { ManualServiceData } from './ManualServiceData';
import type { ProjectHierarchy } from './ProjectHierarchy';

export type HierarchyItemRelation = {
  id?: HierarchyItemRelationId;
  hierarchy: ProjectHierarchy;
  item: Item;
  quantity: number;
  source: HierarchyItemRelation.source;
  addedManually: boolean;
  customized: boolean;
  manualServiceData: Array<ManualServiceData>;
};

export namespace HierarchyItemRelation {

  export enum source {
    COUPLING_RELAY = 'COUPLING_RELAY',
    POWER_ADAPTER = 'POWER_ADAPTER',
    FEED = 'FEED',
    OVERVOLTAGE = 'OVERVOLTAGE',
    FSM = 'FSM',
    KA = 'KA',
    NETWORK = 'NETWORK',
    MISCELLANEOUS = 'MISCELLANEOUS',
    CASINGS_MISC = 'CASINGS_MISC',
    CASINGS = 'CASINGS',
    INSTALLATION_POSITIONS = 'INSTALLATION_POSITIONS',
    DDC = 'DDC',
    OTHER = 'OTHER',
    SERVICE = 'SERVICE',
  }


}


import {
  ProjectService,
  ProjectDescriptionBody,
  ProjectPatchRequest,
  ProjectPostRequest
} from '@/__generated__'
// Projects
const getProject = (id: number) => ProjectService.getProject({ id })
const projects = () => ProjectService.projects()
const getCollaborators = (id: number) => ProjectService.getCollaborators({ id })
const changeProject = (id: number, fields: ProjectPatchRequest | undefined) => ProjectService.changeProject({ id, requestBody: fields })
const addProject = (request: ProjectPostRequest) => ProjectService.addProject({ requestBody: request })
const allProjects = ({ query, page, size, sort }: { query: string; page: number; size: number; sort: Array<string> }) => ProjectService.allProjects({ query, page, size, sort })
const getProjectsUsingQuery = ({ query, page, size, sort }: { query: string; page: number; size: number; sort: Array<string> }) => ProjectService.getProjectsUsingQuery({ query, page, size, sort })
const deleteProject = ({
  projectId
}: {
    projectId: number,
  }) => ProjectService.deleteProject({ projectId })
const duplicateProject = ({
  projectId,
  newName
}: {
      projectId: number,
      newName: string
    }) => ProjectService.duplicateProject({ projectId, newName })
const addCollaborator = ({
  projectId,
  collaboratorId
}: {
  projectId: number,
    collaboratorId: number,
}) => ProjectService.addCollaborator({ projectId, collaboratorId })
const deleteCollaborator = ({
  projectId,
  collaboratorId
}: {
  projectId: number,
  collaboratorId: number,
}) => ProjectService.deleteCollaborator({ projectId, collaboratorId })
const getDescription = ({ projectId }: {projectId: number}) => ProjectService.getDescription({ projectId })
const changeDescription = ({ projectId, body }: {projectId: number, body: ProjectDescriptionBody}) => ProjectService.changeDescription({ projectId, requestBody: body })
export { getProject, changeProject, addProject, projects, allProjects, getProjectsUsingQuery, deleteProject, duplicateProject, getCollaborators, addCollaborator, deleteCollaborator, getDescription, changeDescription }

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MyUser } from '../models/MyUser';
import type { MyUserDTO } from '../models/MyUserDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserService {

  /**
   * Liste aller Benutzer von SALT abrufen
   * Hole eine Liste aller Benutzer
   * @returns MyUser OK
   * @throws ApiError
   */
  public static getUserList(): CancelablePromise<Array<MyUser>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/users',
    });
  }

  /**
   * @deprecated
   * DEPRECATED: Einen Benutzer hinzufügen
   * Fügt einen neuen Benutzer hinzu. (Veraltet: Bitte Active Directory-Funktion /me verwenden.)
   * @returns MyUser OK
   * @throws ApiError
   */
  public static addUser({
    firstName,
    lastName,
    mail,
    profession,
  }: {
    /**
     * Vorname des Benutzers.
     */
    firstName: string,
    /**
     * Nachname des Benutzers.
     */
    lastName: string,
    /**
     * E-Mail-Adresse des Benutzers.
     */
    mail: string,
    /**
     * Beruf des Benutzers (optional).
     */
    profession?: string,
  }): CancelablePromise<MyUser> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/users',
      query: {
        'firstName': firstName,
        'lastName': lastName,
        'mail': mail,
        'profession': profession,
      },
    });
  }

  /**
   * Benutzer anhand der ID abrufen
   * Holt Informationen zu einem spezifischen Benutzer anhand seiner ID.
   * @returns MyUser OK
   * @throws ApiError
   */
  public static getUser({
    id,
  }: {
    /**
     * Die ID des Benutzers.
     */
    id: number,
  }): CancelablePromise<MyUser> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/users/{id}',
      path: {
        'id': id,
      },
    });
  }

  /**
   * Benutzer suchen
   * Sucht Benutzer basierend auf einer Suchabfrage.
   * @returns MyUser OK
   * @throws ApiError
   */
  public static searchUser({
    query,
  }: {
    /**
     * Die Suchabfrage.
     */
    query: string,
  }): CancelablePromise<Array<MyUser>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/users/search',
      query: {
        'query': query,
      },
    });
  }

  /**
   * Aktuellen Benutzer abrufen
   * Holt den aktuellen Benutzer, der durch das Active Directory authentifiziert ist. Falls der Nutzer nicht existiert, legt diese Funktion ihn an.
   * @returns MyUserDTO OK
   * @throws ApiError
   */
  public static getCurrentUser(): CancelablePromise<MyUserDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/users/me',
    });
  }

  /**
   * Überprüfen, ob der aktuelle Benutzer Administrator ist
   * Überprüft, ob der aktuelle Benutzer Administratorrechte besitzt.
   * @returns boolean OK
   * @throws ApiError
   */
  public static isAdmin(): CancelablePromise<boolean> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/users/me/admin',
    });
  }

}

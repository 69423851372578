/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DescriptionResponse } from '../models/DescriptionResponse';
import type { MyUser } from '../models/MyUser';
import type { PageProjectDTO } from '../models/PageProjectDTO';
import type { Project } from '../models/Project';
import type { ProjectDescriptionBody } from '../models/ProjectDescriptionBody';
import type { ProjectDTO } from '../models/ProjectDTO';
import type { ProjectListDTO } from '../models/ProjectListDTO';
import type { ProjectListItemDTO } from '../models/ProjectListItemDTO';
import type { ProjectPatchRequest } from '../models/ProjectPatchRequest';
import type { ProjectPostRequest } from '../models/ProjectPostRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ProjectService {

  /**
   * Projektbeschreibung abrufen
   * Holt die lange Beschreibung (HTML-Format) eines spezifischen Projekts.
   * @returns DescriptionResponse OK
   * @throws ApiError
   */
  public static getDescription({
    projectId,
  }: {
    /**
     * Die Projekt-ID.
     */
    projectId: number,
  }): CancelablePromise<DescriptionResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/projects/{project-id}/description',
      path: {
        'project-id': projectId,
      },
    });
  }

  /**
   * Projektbeschreibung ändern
   * Ändert die lange Beschreibung (HTML-Format) eines spezifischen Projekts. Dabei wird schädlicher Inhalt der HTML gereinigt (sanitized)
   * @returns DescriptionResponse OK
   * @throws ApiError
   */
  public static changeDescription({
    projectId,
    requestBody,
    body,
  }: {
    /**
     * Die Projekt-ID.
     */
    projectId: number,
    requestBody: ProjectDescriptionBody,
    /**
     * Die neue Beschreibung des Projekts.
     */
    body?: any,
  }): CancelablePromise<DescriptionResponse> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/projects/{project-id}/description',
      path: {
        'project-id': projectId,
      },
      query: {
        'body': body,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Liste aller Nutzer-Projekte abrufen
   * Holt eine Liste aller Projekte, die der aktuelle Benutzer verwalten kann.
   * @returns ProjectListDTO OK
   * @throws ApiError
   */
  public static projects(): CancelablePromise<ProjectListDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/projects',
    });
  }

  /**
   * Neues Projekt hinzufügen
   * Fügt ein neues Projekt hinzu.
   * @returns ProjectListItemDTO OK
   * @throws ApiError
   */
  public static addProject({
    requestBody,
    request,
  }: {
    requestBody: ProjectPostRequest,
    /**
     * Die Details des neuen Projekts.
     */
    request?: any,
  }): CancelablePromise<ProjectListItemDTO> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/projects',
      query: {
        'request': request,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Projekt duplizieren
   * Dupliziert ein spezifisches Projekt anhand seiner ID. Man kann seine eigenen sowie Projekte von anderen Personen kopieren.
   * @returns ProjectDTO OK
   * @throws ApiError
   */
  public static duplicateProject({
    projectId,
    newName,
  }: {
    /**
     * Die ID des zu duplizierenden Projekts.
     */
    projectId: number,
    /**
     * Der neue Name für das duplizierte Projekt.
     */
    newName?: string,
  }): CancelablePromise<ProjectDTO> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/projects/{projectId}/duplicate',
      path: {
        'projectId': projectId,
      },
      query: {
        'newName': newName,
      },
    });
  }

  /**
   * Mitarbeiter zu einem Projekt hinzufügen
   * Fügt einen neuen Mitarbeiter zu einem spezifischen Projekt hinzu (Gibt ihm Schreibzugriff). Sich selbst kann man nicht hinzufügen. Nur der Besitzer des Projektes kann Mitarbeiter den Schreibzugriff geben.
   * @returns MyUser OK
   * @throws ApiError
   */
  public static addCollaborator({
    projectId,
    collaboratorId,
  }: {
    /**
     * Die Projekt-ID.
     */
    projectId: number,
    /**
     * Die ID des hinzuzufügenden Mitarbeiters.
     */
    collaboratorId: number,
  }): CancelablePromise<MyUser> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/projects/{project-id}/collaborators/{collaborator-id}',
      path: {
        'project-id': projectId,
        'collaborator-id': collaboratorId,
      },
    });
  }

  /**
   * Mitarbeiter aus einem Projekt entfernen
   * Entfernt einen spezifischen Mitarbeiter aus einem Projekt.
   * @returns any OK
   * @throws ApiError
   */
  public static deleteCollaborator({
    projectId,
    collaboratorId,
  }: {
    /**
     * Die Projekt-ID.
     */
    projectId: number,
    /**
     * Die ID des zu entfernenden Mitarbeiters.
     */
    collaboratorId: number,
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/projects/{project-id}/collaborators/{collaborator-id}',
      path: {
        'project-id': projectId,
        'collaborator-id': collaboratorId,
      },
    });
  }

  /**
   * Spezifisches Projekt abrufen
   * Holt die Details eines spezifischen Projekts anhand seiner ID.
   * @returns ProjectDTO OK
   * @throws ApiError
   */
  public static getProject({
    id,
  }: {
    /**
     * Die Projekt-ID.
     */
    id: number,
  }): CancelablePromise<ProjectDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/projects/{id}',
      path: {
        'id': id,
      },
    });
  }

  /**
   * @deprecated
   * Projekt bearbeiten
   * Bearbeitet die Details eines bestehenden Projekts.
   * @returns Project OK
   * @throws ApiError
   */
  public static editProject({
    id,
    requestBody,
    fields,
  }: {
    /**
     * Die Projekt-ID.
     */
    id: number,
    requestBody: Record<string, any>,
    /**
     * Die zu ändernden Projektdaten.
     */
    fields?: any,
  }): CancelablePromise<Project> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/projects/{id}',
      path: {
        'id': id,
      },
      query: {
        'fields': fields,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Projekt ändern
   * Ändert spezifische Daten eines Projekts.
   * @returns ProjectDTO OK
   * @throws ApiError
   */
  public static changeProject({
    id,
    fields,
    requestBody,
  }: {
    /**
     * Die Projekt-ID.
     */
    id: number,
    /**
     * Die zu ändernden Daten als ProjectPatchRequest.
     */
    fields?: any,
    requestBody?: ProjectPatchRequest,
  }): CancelablePromise<ProjectDTO> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/projects/{id}/change',
      path: {
        'id': id,
      },
      query: {
        'fields': fields,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Mitarbeiter eines Projekts abrufen
   * Holt die Liste der Mitarbeiter, die an einem spezifischen Projekt beteiligt sind.
   * @returns MyUser OK
   * @throws ApiError
   */
  public static getCollaborators({
    id,
  }: {
    /**
     * Die Projekt-ID.
     */
    id: number,
  }): CancelablePromise<Array<MyUser>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/projects/{id}/collaborators',
      path: {
        'id': id,
      },
    });
  }

  /**
   * Projekte aller Nutzer durchsuchen
   * Sucht alle Projekte von Nutzern. Die Query kann dabei mehrere, durch Leerzeichen getrennte Worte enthalten. Durchsucht Name, Ort, Ersteller und Kunde. Enthält Paginierung
   * @returns PageProjectDTO OK
   * @throws ApiError
   */
  public static getProjectsUsingQuery({
    query = '',
    page,
    size = 10,
    sort,
  }: {
    /**
     * Durch Leerzeichen getrennte Suchbegriffe
     */
    query?: string,
    /**
     * Zero-based page index (0..N)
     */
    page?: number,
    /**
     * The size of the page to be returned
     */
    size?: number,
    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     */
    sort?: Array<string>,
  }): CancelablePromise<PageProjectDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/projects/search',
      query: {
        'query': query,
        'page': page,
        'size': size,
        'sort': sort,
      },
    });
  }

  /**
   * Alle öffentlichen Projekte abrufen
   * Holt eine Liste aller öffentlichen Projekte basierend auf einer Suchabfgirage.
   * @returns Project OK
   * @throws ApiError
   */
  public static allProjects({
    query,
    page,
    size = 50,
    sort,
  }: {
    /**
     * Die Suchabfrage, um die Projekte zu filtern.
     */
    query: string,
    /**
     * Zero-based page index (0..N)
     */
    page?: number,
    /**
     * The size of the page to be returned
     */
    size?: number,
    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     */
    sort?: Array<string>,
  }): CancelablePromise<Array<Project>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/projects/public',
      query: {
        'query': query,
        'page': page,
        'size': size,
        'sort': sort,
      },
    });
  }

  /**
   * Projekt löschen
   * Löscht ein spezifisches Projekt anhand seiner ID. Nur Ersteller von Projekten können diese löschen!
   * @returns any OK
   * @throws ApiError
   */
  public static deleteProject({
    projectId,
  }: {
    /**
     * Die ID des Projekts.
     */
    projectId: number,
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/projects/{projectId}',
      path: {
        'projectId': projectId,
      },
    });
  }

}

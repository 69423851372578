/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { SpecificationUnit } from './SpecificationUnit';
import type { SpecificationValue } from './SpecificationValue';

export type Construction = {
  id: number;
  unit?: SpecificationUnit;
  specifications: Array<SpecificationValue>;
  isActive: boolean;
  hasValve: boolean;
  localOverrideOperation: Construction.localOverrideOperation;
  name?: string;
  airSided: boolean;
  delivery: boolean;
  installation: boolean;
  fsm?: boolean;
  cableConnection: Construction.cableConnection;
};

export namespace Construction {

  export enum localOverrideOperation {
    NEVER = 'NEVER',
    NONE = 'NONE',
    TMU = 'TMU',
    BMD = 'BMD',
    COUPLING_RELAY = 'COUPLING_RELAY',
  }

  export enum cableConnection {
    NONE = 'NONE',
    TWOSIDE = 'TWOSIDE',
  }


}


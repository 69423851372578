/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type DdcDtoQuantity = {
  id: number;
  name: string;
  aa?: number;
  ae?: number;
  ba?: number;
  be?: number;
  a_switch?: number;
  b_switch?: number;
  universal?: number;
  gp_pid?: number;
  gp_hzg?: number;
  fw?: number;
  max?: number;
  quantity: number;
  maxItems?: number;
  lvb?: DdcDtoQuantity.lvb;
  orderBy?: number;
  binaryPlantDivisor?: number;
  analogPlantDivisor?: number;
  selectedForAutoLayout: boolean;
  isValid: boolean;
};

export namespace DdcDtoQuantity {

  export enum lvb {
    NEVER = 'NEVER',
    NONE = 'NONE',
    TMU = 'TMU',
    BMD = 'BMD',
    COUPLING_RELAY = 'COUPLING_RELAY',
  }


}

